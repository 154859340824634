import React from 'react';
import Modal from '../../../components/Modal';
import report from '../../../report/report.pdf';
const media = {
  secTitle:
    'Northwest Arkansas (Bentonville, Fayetteville, Rogers & Springdale)',
  cityModal: true,
  type: 'video',
  video: {
    src: 'https://www.youtube.com/embed/zyw3dVOsM0I',
  },
};

export default () => (
  <Modal closeTo="Been" media={media}>
    <p>
      Northwest Arkansas is a rapidly growing region that boasts the corporate
      headquarters for Walmart, Tyson Foods and J.B. Hunt, as well as the home
      of the University of Arkansas. The region is experiencing tremendous
      population growth among people of color. Since 1980, this region’s share
      of people of color has increased from 3.1 percent to nearly one-quarter of
      the population today, seeing strong growth among Latino and Asian/Pacific
      Islander communities. However, there is a distinct inequity among wages.
      The median wage for workers of color is $6 less than that of their white
      counterparts. In Northwest Arkansas, 33 percent of jobs require some
      college education, and despite the presence of a major state university in
      the area, only 40 percent of whites and blacks would be eligible for these
      jobs. In Bentonville, we conducted focus groups, individual interviews and
      a small group listening session where we heard the opinions of black,
      white and Latino residents. Focus groups were conducted with both black
      and white residents in the Northwest Arkansas region, from Bentonville to
      Fayetteville.
    </p>

    <p>
      In discussions with community leaders, we heard that with growth comes
      uncomfortable change for a lot of people in the region and that the
      benefits are not evenly distributed by race, gender or class. Residents
      expressed their pride in the region’s growing diversity, which has been
      fueled by an influx of corporate investment. But, this rapid growth and
      expanding economic opportunity is leaving some people behind. We heard
      that there is a lack of diversity among local leadership, including local
      government, businesses and community boards, which highlights that while
      this region may not be a hostile place for people of color, it still
      struggles to address system and institutional barriers to equity and
      inclusion. Learn more by reading the{' '}
      <a
        target="_blank"
        rel="noopener noreferrer"
        className="report-link"
        href={report}
      >
        full report.
      </a>
    </p>
  </Modal>
);
